import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { Fragment, useState } from "react";

// Date Picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

// Material Icons

// Phone Input
import "react-phone-input-2/lib/style.css";

// Assets
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import completedImg from "../../assets/completed.svg";
import warningImg from "../../assets/warning.svg";
import { WALLET_API } from "../../lib/axios";
import { useTranslation } from "react-i18next";

const steps = ["Personal Information"];

export function ManualKYCStepper({ ...props }) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const { data, isClose } = props;
  const defaultForm = {
    fullName: data.fullname ?? "",
    identityId: data.nik,
    birthDate: dayjs(data.dob),
  };

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState(defaultForm);

  const [cookies] = useCookies(["auth"]);

  // Handle form
  const handleSubmitPressed = (e) => {
    e.preventDefault();
    WALLET_API.post("/account/kyc/request", formData, {
      headers: {
        Authorization: `Bearer ${cookies.auth}`,
      },
    })
      .then(() => {
        setActiveStep(1);
      })
      .catch((err) => {
        console.error(err);
        navigate("/?err=verify");
        navigate(0);
      });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleDate = (e) => {
    setFormData({
      ...formData,
      birthDate: dayjs(e),
    });
  };

  const showStepperData = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
            <Grid item xs={12} md={12}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                NIK
              </Typography>
              <TextField
                required
                fullWidth
                placeholder="NIK"
                inputProps={{
                  style: {
                    fontWeight: 400,
                    fontSize: "14px",
                    height: "15px",
                  },
                }}
                name="identityId"
                value={formData.identityId}
                id="nik"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                Fullname
              </Typography>
              <TextField
                required
                fullWidth
                placeholder="Fullname"
                value={formData.fullName}
                inputProps={{
                  style: {
                    fontWeight: 400,
                    fontSize: "14px",
                    height: "15px",
                  },
                }}
                name="fullName"
                id="fullname"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                Date of Birth
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"en-gb"}
              >
                <DatePicker
                  name="dob"
                  id="dob"
                  value={formData.birthDate}
                  onChange={handleDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "50px",
                      fontSize: "14px",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
            <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
              <img src={warningImg} width="185x" alt="GIDR - Warning" />
              <Typography
                variant="body2"
                sx={{ mb: 2, mt: 2 }}
                fontWeight="bold"
                align="center"
              >
                It looks like we have some problem, please try again!
              </Typography>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      {activeStep === steps.length ? (
        <Fragment>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
              <img src={completedImg} width="185x" alt="GIDR - Completed" />
              <Typography
                variant="body2"
                sx={{ mb: 2, mt: 2 }}
                fontWeight="bold"
                align="center"
              >
                {t("verification.completed")}
                <br />
                {t("verification.pleasewait")}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={isClose}>Close</Button>
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          {showStepperData(activeStep)}
          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
            <Button color="inherit" onClick={isClose} sx={{ mr: 1 }}>
              Exit
            </Button>
            <Button onClick={handleSubmitPressed}>
              {activeStep === steps.length - 1 ? "Verify" : "Next"}
            </Button>
          </Box>
        </Fragment>
      )}
    </Box>
  );
}
