import React, { useState, Fragment } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

// Components
import KYCDialog from "../dialog/KYC";

export function KYCAlert({ ...props }) {
  const { description, data } = props;
  const [kycDialog, setKycDialog] = useState(false);

  const handleOpenKycDialog = () => {
    setKycDialog(true);
  };

  const handleCloseKycDialog = () => {
    setKycDialog(false);
  };

  return (
    <Fragment>
      <KYCDialog
        data={data}
        isOpen={kycDialog}
        isClose={handleCloseKycDialog}
      />
      <Alert
        severity="error"
        action={
          <Button
            color="inherit"
            size="small"
            sx={{ fontWeight: 800 }}
            onClick={handleOpenKycDialog}
          >
            Verify Now
          </Button>
        }
      >
        {description}
      </Alert>
    </Fragment>
  );
}
