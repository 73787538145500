import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

// Date Picker
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

// Assets
import redeemedImg from "../../assets/redeem.svg";

// Translation
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DAYOFF_API, WALLET_API } from "../../lib/axios";
import { useCookies } from "react-cookie";
import {
  Box,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { Star, StarBorder } from "@mui/icons-material";

export function RedemptionDialog({ ...props }) {
  const { t } = useTranslation("common");
  const { isOpen, isClose, profile } = props;
  const [cookies] = useCookies(["auth"]);
  const navigate = useNavigate();

  const [bookings, setBookings] = useState([]);
  const [dayoffs, setDayOffs] = useState([]);
  const [rating, setRating] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([9, 10, 11, 12, 13, 14]);
  useEffect(() => {
    DAYOFF_API.get("/api")
      .then((res) => {
        return res.data;
      })
      .then((res) => res.map((v) => (v.tanggal ? dayjs(v.tanggal) : null)))
      .catch(() => [])
      .then((res) => setDayOffs(res));
    if (cookies == null) {
      navigate("/login");
    } else {
      WALLET_API.get("/account/redeem", {
        headers: {
          Authorization: `Bearer ${cookies.auth}`,
        },
      })
      .then((res) => setBookings(res.data))
      .catch(() => setBookings([]));
    }
  }, [cookies, navigate]);

  const handleBookingDateChange = (newdob) => {
    const bookingDate = dayjs(newdob);
    const slots = [9, 10, 11, 12, 13, 14].filter((x) => {
      const booked = bookings
        .filter((v) => dayjs(bookingDate).isSame(v.appointmentDate, "day"))
        .map((v) => v.timeSlot);
      return !booked.includes(x);
    });
    setAvailableSlots(slots);
    return setFormData({
      ...formData,
      appointmentDate: newdob,
    });
  };

  const defaultForm = {
    walletaddress: !profile.walletaddress ? "-" : profile.walletaddress,
    nik: !profile.nik ? "-" : profile.nik,
    fullname: !profile.fullname ? "-" : profile.fullname,
    email: !profile.email ? "-" : profile.email,
    appointmentDate: dayjs(null),
  };

  const [formData, setFormData] = useState(defaultForm);
  const [redemptionAmount, setRedemptionAmount] = useState("");

  const handleChangeRedemptionAmount = (e) => {
    setRedemptionAmount(e.target.value);
  };

  const [timeSlot, setTimeSlot] = useState("");
  const [npsRating, setNps] = React.useState(0);
  const [csatRating, setCsat] = React.useState(0);

  const handleChangeTimeSlot = (e) => {
    const newTime = e.target.value;
    setTimeSlot(newTime);
    setFormData({
      appointmentDate: dayjs(formData.appointmentDate).set(
        "hour",
        Number(newTime)
      ),
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const toSend = {
      ...formData,
      amount: redemptionAmount,
      timeSlot,
    };
    WALLET_API.post("/account/redeem/request", toSend, {
      headers: {
        Authorization: `Bearer ${cookies.auth}`,
      },
    })
      .then(() => {
        setRating(true);
      })
      .catch((err) => {
        if (err.response?.status === 406) {
          navigate("/?err=insufficientredeem");
          navigate(0);
          return;
        }
        navigate("/?err=redeem");
        navigate(0);
      });
  };

  const handleRatingSubmit = (e) => {
    e.preventDefault();
    WALLET_API.post(
      "/rating",
      {
        csat: csatRating,
        nps: npsRating,
      },
      {
        headers: {
          Authorization: `Bearer ${cookies.auth}`,
        },
      }
    ).finally(() => {
      navigate("/?msg=redeem");
      navigate(0);
    });
  };

  const isDayOff = (date) => {
    const day = date.day();

    return (
      day === 0 ||
      day === 6 ||
      dayoffs.find((v) => dayjs(date).isSame(v, "day"))
    );
  };

  function RedemptionInfoStepper() {
    const { t } = useTranslation("common");

    return (
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Stepper
              sx={{
                "& .MuiStepConnector-line": {
                  minHeight: "15px",
                },
              }}
              orientation="vertical"
            >
              <Step active>
                <StepLabel>{t("rating.step1")}</StepLabel>
                <StepContent>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    sx={{ my: 4 }}
                  >
                    {[1, 2, 3, 4, 5].map((x, i) => (
                      <Button key={i} onClick={() => setNps(x)}>
                        {x <= npsRating ? <Star /> : <StarBorder />}
                      </Button>
                    ))}
                  </Stack>
                </StepContent>
              </Step>
              <Step active>
                <StepLabel>{t("rating.step2")}</StepLabel>
                <StepContent>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    sx={{ my: 4 }}
                  >
                    {[1, 2, 3, 4, 5].map((x, i) => (
                      <Button key={i} onClick={() => setCsat(x)}>
                        {x <= csatRating ? <Star /> : <StarBorder />}
                      </Button>
                    ))}
                  </Stack>
                </StepContent>
              </Step>
            </Stepper>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return rating ? (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={isClose}
      maxWidth={"sm"}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Typography variant="h4" fontWeight="600">
          {t("redeemGIDR.modal.description")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <RedemptionInfoStepper />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRatingSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={isClose}
      maxWidth={"sm"}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Typography variant="h4" fontWeight="600">
          {t("redeemGIDR.modal.title")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12} sx={{ textAlign: "center", mb: 1 }}>
            <img src={redeemedImg} width="220px" alt="GIDR - Redeemed" />
          </Grid>
          <Grid item xs={12} md={12} sx={{ mb: 1 }}>
            <Typography variant="body1" fontWeight="600">
              {t("redeemGIDR.modal.accountInformation.title")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" fontWeight="600" sx={{ mb: "8px" }}>
                  {t("redeemGIDR.modal.accountInformation.walletAddress")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {defaultForm.walletaddress}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.nik")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {defaultForm.nik}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.fullname")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {defaultForm.fullname}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.emailAddress")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {defaultForm.email}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.emailStatus")}
                </Typography>
                <Chip
                  label={
                    profile.statusemail !== "UNVERIFIED"
                      ? t("kycStatus.verified")
                      : t("kycStatus.notVerified")
                  }
                  color={
                    profile.statusemail !== "UNVERIFIED" ? "success" : "error"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Collection Date
                </Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"en-gb"}
                >
                  <DatePicker
                    onChange={handleBookingDateChange}
                    disablePast
                    shouldDisableDate={isDayOff}
                    minDate={dayjs().add(3, "day")}
                    name="collectionDate"
                    id="collectionDate"
                    value={formData.appointmentDate || ""}
                    slotProps={{
                      day: (day) => {
                        const isHolidayDay = isDayOff(day.day);
                        return {
                          sx: isHolidayDay ? { color: "red!important" } : {},
                        };
                      },
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "50px",
                        fontSize: "14px",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.timeSlot")}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    disabled={!formData.appointmentDate.isValid()}
                    value={timeSlot}
                    fullWidth
                    displayEmpty
                    onChange={handleChangeTimeSlot}
                    style={{ height: "50px", fontSize: "14px" }}
                  >
                    <MenuItem value="">
                      <em>
                        {!formData.appointmentDate.isValid()
                          ? t(
                              "redeemGIDR.modal.accountInformation.timeSlotPlaceholderEmptyDate"
                            )
                          : availableSlots.length === 0
                          ? "No slots available"
                          : t(
                              "redeemGIDR.modal.accountInformation.timeSlotPlaceholder"
                            )}
                      </em>
                    </MenuItem>
                    {availableSlots.map((x, i) => {
                      return (
                        <MenuItem value={x} key={i}>
                          {x >= 10 ? x.toString() : "0" + x}:00 - {x + 1}:00 WIB
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.redemptionAmount")}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={redemptionAmount}
                    fullWidth
                    label={null}
                    onChange={handleChangeRedemptionAmount}
                    style={{ height: "50px", fontSize: "14px" }}
                  >
                    <MenuItem value="">
                      <em>
                        {t(
                          "redeemGIDR.modal.accountInformation.redemptionAmountPlaceholder"
                        )}
                      </em>
                    </MenuItem>
                    {[1, 5, 10].map((x, i) => (
                      <MenuItem value={x} key={i}>
                        {x} GIDR
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Redemption Fee
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {redemptionAmount * 0.06} GIDR
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  disabled={
                    profile.statusemail === "UNVERIFIED" ||
                    redemptionAmount === ""
                  }
                  sx={{ fontWeight: "600", boxShadow: "unset" }}
                  fullWidth
                  onClick={handleFormSubmit}
                >
                  {t("redeemGIDR.modal.accountInformation.button")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={isClose}>{t("redeemGIDR.modal.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
