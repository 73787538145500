import React, { Fragment, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

// Assets
import headerBg from "../assets/header.png";

// Components
import AppBar from "../components/AppBar";
import Title from "../components/Title";
import HistoryCard from "../components/card/History";
import RedemptionTable from "../components/table/Redemption";

// API
import { WALLET_API } from "../lib/axios";

// Translation
import { useTranslation } from "react-i18next";

//Cookies
import { useCookies } from "react-cookie";

export default function TransactionHistory() {
  const { t } = useTranslation("common");

  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [cookies,,removeCookie] = useCookies(['auth']);

  useEffect(() => {
    if (cookies == null) {
      navigate("/login");
    } else {
      WALLET_API.get("/account/redeem/history", {
        headers: {
          Authorization: `Bearer ${cookies.auth}`,
        },
      })
        .then((res) => res.data)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          if (err.status === 401) {
            removeCookie('auth');
            navigate("/login");
          }
        });
    }
  }, [cookies, removeCookie, navigate]);

  return (
    <Fragment>
      <Box
        sx={{
          backgroundImage: `url(${headerBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "350px",
          width: "100vw",
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 1,
          borderRadius: "unset",
          boxShadow: "unset",
        }}
      />
      <AppBar />
      <Container maxWidth="lg" sx={{ p: 1 }}>
        <div style={{ position: "relative" }}>
          <Title
            header={t("navigation.redemption")}
            isBreadcrumbs={true}
            mainPage={t("navigation.dashboard")}
            subPage={t("navigation.redemption")}
          />
        </div>
        <div style={{ position: "relative" }}>
          <Grid
            container
            spacing={2}
            sx={{ position: "absolute", pt: 4, left: 0, right: 0 }}
          >
            <Grid item xs={12} md={12}>
              <HistoryCard
                title={t("redemption.header")}
                amount={data.length}
                description={t("redemption.subHeader")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2, pb: "120px" }}>
              <Paper
                sx={{
                  p: 4,
                  boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <RedemptionTable data={data} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Fragment>
  );
}
