import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";

// Assets
import cardBg from "../../assets/card.png";

// Icons
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

// Translation
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function PersonalInformationCard({ ...props }) {
  const { t } = useTranslation("common");
  const { email, ekycverified } = props;
  // const [cookies] = useCookies(["auth"]);
  // const [isTimerRunning, setIsTimerRunning] = React.useState(false);
  // const [secondsLeft, setSecondsLeft] = React.useState(60); // 1 minute in seconds

  // React.useEffect(() => {
  //   let intervalId;

  //   if (isTimerRunning) {
  //     intervalId = setInterval(() => {
  //       setSecondsLeft((prevSeconds) => prevSeconds - 1);
  //     }, 1000);
  //   }

  //   return () => clearInterval(intervalId);
  // }, [isTimerRunning]);

  // const handleButtonClick = () => {
  //   if (!isTimerRunning) {
  //     WALLET_API.post(
  //       "/account/reverify",
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${cookies.auth}`,
  //         },
  //       }
  //     );
  //     setIsTimerRunning(true);
  //   }
  // };

  return (
    <Card variant="outlined" sx={{ border: "unset" }}>
      <CardContent
        sx={{
          backgroundImage: `url(${cardBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundColor: "unset",
          padding: 2,
        }}
      >
        <Grid container spacing={2} sx={{ pb: 1.1 }}>
          <Grid item xs={6} md={6} sx={{ alignContent: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              {t("accountInformation.title")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sx={{ textAlign: "-webkit-right" }}>
            <Avatar sx={{ backgroundColor: "#141517" }}>
              <PersonOutlineIcon sx={{ color: "#E3AD1A" }} />
            </Avatar>
          </Grid>
        </Grid>
        <Typography variant="body2" color="#4d4d4d" gutterBottom>
          {t("accountInformation.referralCode")}
        </Typography>
        <Typography variant="h2">-</Typography>
        <Grid
          container
          direction="row"
          justifyContent="left"
          spacing={4}
          sx={{ pt: 1 }}
        >
          <Grid item>
            <Typography variant="body2" color="#4d4d4d" gutterBottom>
              {t("accountInformation.emailAddress")}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              {email}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="#4d4d4d" gutterBottom>
              {t("accountInformation.kycStatus")}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <Typography
                variant="body2"
                fontWeight="600"
                color={ekycverified === "VERIFIED" ? "#2e7d32" : ekycverified === "PENDING" ? "yellow" : "#d32f2f"}
              >
                {ekycverified === "VERIFIED"
                  ? t("kycStatus.verified")
                  : ekycverified === "PENDING"
                  ? t("kycStatus.pending")
                  : t("kycStatus.notVerified")}
              </Typography>
            </Stack>
          </Grid>
          {/* <Grid item>
            <Typography variant="body2" color="#4d4d4d" gutterBottom>
              {t("accountInformation.emailStatus")}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <Typography
                variant="body2"
                fontWeight="600"
                color={emailverified ? "#2e7d32" : "#d32f2f"}
              >
                {emailverified
                  ? t("kycStatus.verified")
                  : t("kycStatus.notVerified")}
              </Typography>
              <Button
                variant="contained"
                disabled={isTimerRunning}
                onClick={handleButtonClick}
                size="small"
              >
                {isTimerRunning
                  ? `Wait ${secondsLeft} seconds`
                  : t("accountInformation.resend")}
              </Button>
            </Stack>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
}
