import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";

//
import { ManualKYCStepper } from "../stepper/ManualKYC";

export default function KYCDialog({ ...props }) {
  const { isOpen, isClose, data } = props;

  return (
    <Dialog fullWidth open={isOpen} onClose={isClose} maxWidth={"sm"}>
      <DialogTitle>
          KYC Form
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2">
              In order to access all the features of GIDR Wallet, we need you to
              complete KYC Process, please input your data:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1.5} sx={{ mt: 1 }}>
              <Grid item xs={12} md={12}>
                <ManualKYCStepper data={data} isClose={isClose} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={isClose}>Cancel</Button> */}
        {/* <Button type="submit">Verify</Button> */}
      </DialogActions>
    </Dialog>
  );
}
