import axios from "axios";

const WALLET_API = axios.create({
  baseURL: process.env.REACT_APP_WALLET_URL,
});

const DAYOFF_API = axios.create({
  baseURL: "https://dayoffapi.vercel.app/",
});

export { WALLET_API, DAYOFF_API };