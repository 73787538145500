import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

// Translation
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

export default function LogoutDialog({ ...props }) {
  const { t } = useTranslation("common");

  const navigate = useNavigate();
  const [,,removeCookie] = useCookies(['auth'])

  const { isOpen, isClose } = props;
  const width = "xs";

  const handleLogout = () => {
    removeCookie('auth')
    navigate("/");
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={isClose} maxWidth={width}>
      <DialogTitle sx={{ fontWeight: 700 }}>{t("logOut.title")}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="#7e7e7e" gutterBottom>
          {t("logOut.description")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLogout}>{t("logOut.action")}</Button>
        <Button onClick={isClose}>{t("logOut.cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
