import React, { Fragment, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";

// Icons
import InfoIcon from "@mui/icons-material/Info";

// Components
import RedemptionDetailDialog from "../dialog/RedemptionDetail";

// Translation
import { useTranslation } from "react-i18next";

export default function RedemptionHistoryTable({ ...props }) {
  const { t } = useTranslation("common");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const { data } = props;
  const [selectedData, setSelectedData] = useState([]);

  const columns = [
    { id: "id", label: t("redemption.table.no") },
    {
      id: "appointmentDate",
      label: t("redemption.table.date"),
      align: "left",
    },
    { id: "amount", label: t("redemption.table.amount"), align: "left" },
    { id: "status", label: t("redemption.table.status"), align: "right" },
    { id: "action", label: t("redemption.table.detail"), align: "center" },
  ];

  const handleCloseDetailDialog = () => {
    setOpenDetailDialog(false);
  };

  const filterStatus = (data) => {
    switch (data) {
      case "REJECTED":
        return <Chip label="Rejected" color="error" />;
      case "CANCELLED":
        return <Chip label="Cancelled" color="error" />;
      case "REDEEMED":
        return <Chip label="Redeemed" color="success" />;
        case "ON_WEB2":
          return <Chip label="On Web2" color="success" />;
      default:
        return <Chip label="On Confirmation" color="warning" />;
    }
  };

  const rows =
    !data
      ? []
      : data.map((v, i) => ({
          id: i + 1,
          amount: `${v.amount} GIDR`,
          appointmentDate: new Date(v.appointmentDate).toLocaleString(),
          status: filterStatus(v.status),
          action: (
            <IconButton
              onClick={() => {
                setSelectedData(v);
                setOpenDetailDialog(true);
              }}
            >
              <InfoIcon />
            </IconButton>
          ),
        }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Fragment>
      <RedemptionDetailDialog
        isOpen={openDetailDialog}
        isClose={handleCloseDetailDialog}
        data={selectedData}
      />
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#E3AD1A",
                      color: "#ffffff",
                      fontWeight: 700,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Fragment>
  );
}
